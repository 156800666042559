import React from 'react';
import Spacing from '../Spacing';
import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How long does it take to develop a custom website?',
    content:
      '  It depends on the project and its complexity whether the entire development period will take a couple of weeks or several months. We give the exact timeline upon knowing the exact requirements.',
  },
  {
    title: '02. Can you help with redesigning an existing website?',
    content:
      'Yes, we provide a complete website redesign service. If you need a visual refresh, a complete overhaul, or something in between, we will work with you to improve your site’s look, interactivity and performance.',
  },
  {
    title: '03. Do you offer e-commerce solutions for small businesses?',
    content:
      'We do indeed! We provide small producers and those opening small stores with all the e-commerce tools they need. Our services are customisable for your size, from startup to large multinational company.',
  },
  {
    title: '04. How do you ensure website security?',
    content:
      'We use the latest security protocols to keep your site safe. This would include SSL installations, secure coding practices, regular security scans for vulnerabilities, and proactive monitoring.',
  },
  {
    title: '05. What kind of support do you offer after the website is launched?',
    content:
      ' You shouldn’t have to worry about maintaining your website or understanding the complexities of programming languages such as HTML, CSS, PHP, or JavaScript. We take care of all the maintenance required to keep it up-to-date and safely managing it, be it through software updates, scans for security vulnerabilities, performance optimisation, or answering your queries in the unfortunate event of any technical issues that may arise.'},
];
const serviceListData = [
  {
    title: 'User-Centric Experience',
    subtitle:
      ' We design websites that prioritize user experience, ensuring visitors enjoy intuitive navigation and a seamless journey from start to finish.',
    imgUrl: '/images/sd/wd/1.jpg',
    href: '#',
  },
  {
    title: 'Enhanced Brand Presence',
    subtitle:
      'Our custom web designs reflect your brand’s identity, helping to build a strong online presence that attracts and retains customers.',
    imgUrl: '/images/sd/wd/2.jpg',
    href: '#',
  },
  {
    title: 'Responsive Design',
    subtitle:
      'We create websites that are fully responsive, ensuring they look and function perfectly on all devices, from desktops to mobile phones.',
    imgUrl: '/images/sd/wd/3.jpg',
    href: '#',
  },
  {
    title: 'SEO-Friendly Structure',
    subtitle:
      ' We build websites with SEO best practices in mind, laying the foundation for better search engine rankings and increased visibility.',
    imgUrl: '/images/sd/wd/5.jpg',
    href: '#',
  },
];

export default function WebDesign() {
  pageTitle('Web Design');

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Designing Seamless Digital Experiences"
        subTitle="Web Design"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/wd/wd.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
              Your website is often the first impression customers have of your brand, 
              and our Web Design services ensure it’s a lasting one. We create visually 
              stunning, user-friendly websites that are not only beautiful but also 
              intuitive to navigate. By focusing on both aesthetics and functionality, 
              we deliver designs that engage visitors, enhance user experience, and 
              drive conversions, helping your business achieve its online goals.
            </p>
            <p>
             Our designs are crafted with your audience in mind, ensuring your website 
             is both visually compelling and easy to use. We transform your vision into
              a digital experience that resonates with visitors.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Transform your online presence with custom web design. 
                We craft SEO-friendly, visually stunning websites that 
                enhance user experience and differentiate your brand from 
                the competition.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Discovery & Research"
                  subTitle="We start by understanding your business goals, target audience, and competitive landscape. This helps us create a strategic plan that aligns with your vision and market demands."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Planning & Strategy"
                  subTitle="In this phase, we outline the website structure, including key features, functionalities, and user flows. This blueprint serves as the foundation for the entire project."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Design & Prototyping"
                  subTitle="Our creative team begins crafting the visual elements of your site, creating wireframes and prototypes to give you a clear vision of the final product."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Development & Integration"
                  subTitle="Using the latest technologies, we bring the design to life. Our developers ensure that the site is fully functional, secure, and optimized for performance."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Testing & Quality Assurance"
                  subTitle="Before the launch, we conduct rigorous testing to ensure everything works flawlessly across all devices and browsers. We check for performance, security, and usability."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Launch & Optimization"
                  subTitle="After your approval, we launch the website and monitor its performance. We also provide post-launch support and continuous optimization to keep your website at peak performance."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/fLNLBTehdEM"
          bgUrl="/images/sd/wd/webvideo.png"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div>

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
