import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How long does it take to produce a video?',
    content:
      'The time it takes to produce a video for your business depends on the types of video. If it’s just a once off simple video to put on your social media, it will take about two weeks. However, if it’s a full out promotional or corporate video, it could take as long as a couple of months. Once we have talked about all of your needs, we will send you the time schedule for your project.',
  },
  {
    title: '02. What types of videos can you create?',
    content:
      'We produce wide arrays of videos, such as promotional videos, explainer videos, corporate videos, social media clips, event videos, 360 content and more. Whatever type of video you want, tell us about it, and we will adjust our offerings to fit your needs.',
  },
  {
    title: '03. Do you provide video scripts and storyboards?',
    content:
      ' Yes – all the pre-production work – writing the script, storyboarding – we do that. And if we do the pre-production properly, then you know exactly what you’re going to be making. So, before you ever get onto set with some lights and a crew and a group of actors, you know exactly what you’re going to be filming. That way, the actual production process becomes very streamlined and efficient.',
  },
  {
    title: '04. Can you help distribute the video on social media?',
    content:
      'Indeed, if you want to produce the video, we would also help you distribute it on the right channels, as well as advise you on how to optimise your video in the right channels for your target audience.',
  },
  {
    title: '05. What if I need revisions after the video is completed?',
    content:
      ' We offer a round of revisions after the initial video is completed to ensure you’re completely satisfied with the final product. If further revisions are needed, we’ll work with you to make sure the video meets your expectations.',
  },
];
const serviceListData = [
  {
    title: 'Engaging Visual Storytelling',
    subtitle:
      'We craft dynamic videos that captivate audiences, delivering your brand message in an impactful way.',
    imgUrl: '/images/sd/vc/1.png',
    href: '#',
  },
  {
    title: 'Improved Conversion Rates',
    subtitle:
      'Creating videos effectively communicate your value proposition, driving viewer engagement and turning interest into action.',
    imgUrl: '/images/sd/vc/2.png',
    href: '#',
  },
  {
    title: 'Expanded Reach',
    subtitle:
      ' Videos optimized for shareability help extend your brand’s reach across platforms, amplifying your message to a wider audience.',
    imgUrl: '/images/sd/vc/3.png',
    href: '#',
  },
  {
    title: 'Versatile Marketing Tool',
    subtitle:
      ' Video content becomes adaptable for multiple platforms, from social media to websites, providing valuable assets that elevate your marketing efforts.',
    imgUrl: '/images/sd/vc/5.png',
    href: '#',
  },
];

export default function VideoContent() {
  pageTitle('Video Content');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Creating Engaging Visual Narratives"
        subTitle="Video Content"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/cw/copy.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Bring your brand to life with compelling video content that captivates 
             and resonates with your audience. Our video production services are designed 
             to tell your story through visually stunning and emotionally engaging narratives. 
             Whether it's promotional videos, product demonstrations, or storytelling pieces, 
             we craft videos that not only capture attention but also inspire action, making 
             your brand unforgettable in a crowded digital space.
            </p>
            <p>
             We turn your vision into dynamic visual content that connects with your audience. 
             From concept to final cut, our videos are designed to leave a lasting impression 
             and drive meaningful engagement.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Boost engagement with professionally crafted video content. 
                Our team creates visually compelling, SEO-friendly videos that 
                captivate your audience and differentiate your brand in the 
                crowded digital landscape.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Strategic Concept Development"
                  subTitle="Align video concepts with your brand’s goals, ensuring they resonate with your target audience."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Storyboarding and Scripting"
                  subTitle="Create detailed storyboards and scripts for a cohesive and impactful narrative."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="  High-Quality Production"
                  subTitle="Use state-of-the-art equipment to produce visually stunning, professional-grade videos."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Post-Production Excellence"
                  subTitle="Enhance videos with editing, sound design, and visual effects for a polished final product."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Platform Optimization"
                  subTitle="Tailor videos for various platforms, maximizing reach and effectiveness across channels."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Analytics and Performance Review"
                  subTitle="Track and analyze video performance, providing insights to inform future content strategies."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
