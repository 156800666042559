import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. What types of data work best for infographics?',
    content:
      'Infographics are not only for statistics, but they also support any data (statistics, survey outcomes, timelines, workflows, comparisons and more). We help you identify the most important data points to include in your infographic so it speaks.',
  },
  {
    title: '02. Can you design infographics in my brand’s style?',
    content:
      'Absolutely!We create infographics completely consistent with your brand colours, fonts, and visual identity. We make sure each infographic integrates with your overall brand.',
  },
  {
    title: '03. How do you ensure the infographics are easy to understand?',
    content:
      'In our design philosophy, we focus on simpliness. We collaborate with you to determine the most salient points and present the infographic in a coherent way for your viewers to consume. We also employ visual hierarchy and intuitive design elements to guide the viewer’s eye.',
  },
  {
    title: '04. What formats do you deliver the infographics in?',
    content:
      'We provide infographics in the formats you choose, PNG, JPEG, PDF, and SVG. We also offer the infographic in various resolutions to help it look good on both digital and print formats.',
  },
  {
    title: '05. Can you create infographics for social media?',
    content:
      'Yes! We specialise in designing infographics for social media sites that are readable, shareable, and according to the platforms’ requirements. On Instagram, Facebook, LinkedIn or Twitter, we design the piece to be as impactful and engaging as possible.',
  },
];
const serviceListData = [
  {
    title: 'Instant Clarity and Impact',
    subtitle:
      'We transform complex data into clear, engaging visuals that capture attention and make your key messages easy to understand.',
    imgUrl: '/images/sd/infographics/1.png',
    href: '#',
  },
  {
    title: 'Amplified Audience Engagement',
    subtitle:
      'Our visually compelling infographics resonate with your audience, driving higher engagement and boosting brand visibility.',
    imgUrl: '/images/sd/infographics/2.png',
    href: '#',
  },
  {
    title: 'Increased Content Shareability',
    subtitle:
      ' Our infographics are designed to be highly shareable, expanding your content’s reach and driving more traffic to your website.',
    imgUrl: '/images/sd/infographics/3.png',
    href: '#',
  },
  {
    title: 'Strategic Marketing',
    subtitle:
      ' Infographics seamlessly integrate into your content marketing strategy, enhancing the effectiveness of your campaigns and supporting lead generation.',
    imgUrl: '/images/sd/infographics/4.png',
    href: '#',
  },
];

export default function InfoGraphics() {
  pageTitle('Infographics');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Visualizing Data with Impact"
        subTitle="Infographics"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/infographics/info.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
            Transform complex information into visually compelling stories with our 
            Infographics services. We design infographics that simplify data and make 
            your message more accessible and engaging for your audience. Whether it’s 
            for presentations, marketing campaigns, or educational purposes, our infographics 
            are crafted to communicate your ideas clearly and creatively, helping you capture 
            attention and convey information effectively.
            </p>
            <p>
              Our infographics are designed to make your data not only understandable but 
              also memorable. We focus on clarity and creativity, ensuring your 
              message stands out and resonates with your audience.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Communicate complex ideas with compelling infographics. 
                Our SEO-optimized designs captivate audiences, boost engagement, 
                and clearly differentiate your brand in the digital space.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Research and Data Collection"
                  subTitle="We gather all relevant information, statistics, and insights that will be used in the infographic, ensuring that the content is accurate, credible, and aligned with your objectives. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Conceptualization and Storyboarding"
                  subTitle=" This step ensures that the narrativity is clear and logical, guiding the viewer through the data in an engaging and easily digestible way. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Design and Visualization"
                  subTitle="Our design team brings the storyboard to life by creating visually stunning graphics that capture attention and convey the information effectively. "
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="  Brand Integration"
                  subTitle="We seamlessly integrate your brand’s visual identity into the infographic, ensuring consistency with your overall branding. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Review and Refinement"
                  subTitle="Before finalizing the infographic, we present it to you for review and feedback to ensure it perfectly aligns with your vision and goals. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Distribution and Amplification"
                  subTitle="Once the infographic is finalized, we assist with its distribution across your preferred channels or as part of a larger content marketing campaign."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
