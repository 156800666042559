import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How do you capture my brand’s message in the scripts you write?',
    content:
      ' The process kicks off with a detailed consultation to delve into the origins and purpose of your brand, its creative principles and signature, and the characteristics and needs of your target audience. From that point on, we deliver well-written scripts that faithfully capture the essence of your brand, maintaining the necessary consistency to develop brand-new characters that naturally sound like extensions of your audio universe.',
  },
  {
    title: '02. What types of scripts do you write?',
    content:
      'Whether you are looking for a script for a promotional video, a social media clip, an explainer video or a corporate presentation, our range of expertise means we are sure to have a script that will appeal to you. Whatever text is required, we always make sure that it meets your specific needs and objectives.',
  },
  {
    title: '03. How do you ensure the scripts are engaging and effective?',
    content:
      'We’re using storytelling infrastructure to draw people in, effectively using hooks, leading them towards the message and having them take a desired action. We are scripting content. The content of the message must be persuasive, consistently on-brand and tailored to the audience.',
  },
  {
    title: '04. Can you help with script revisions if needed?',
    content:
      'Yes, of course! We will provide you with a revision until you are fully satisfied with the end product. We value your feedback and will work alongside you until the product is perfect.',
  },
  {
    title: '05. What is the typical turnaround time for a script?',
    content:
      'Turnaround time will depend upon the scope of your project but scripts normally can be achieved within 5-7 business days. If you have a special deadline, we can always discuss some expedited options.',
  },
];
const serviceListData = [
  {
    title: 'Compelling Narratives',
    subtitle:
      ' We create scripts that captivate your audience, delivering your message clearly and effectively, ensuring viewer engagement from start to finish.',
    imgUrl: '/images/sd/sw/1.png',
    href: '#',
  },
  {
    title: 'Aligned with Brand Voice',
    subtitle:
      'Our scripts are tailored to reflect your brand’s tone and messaging, ensuring consistency and strengthening your brand identity.',
    imgUrl: '/images/sd/sw/2.png',
    href: '#',
  },
  {
    title: 'Increased Conversion Potential',
    subtitle:
      'We incorporate persuasive elements into our scripts that guide your audience toward taking the desired action, boosting conversion rates.',
    imgUrl: '/images/sd/sw/3.png',
    href: '#',
  },
  {
    title: 'Streamlined Production',
    subtitle:
      'Our well-structured scripts provide clear direction, facilitating a smooth production process and ensuring the final content meets your goals.',
    imgUrl: '/images/sd/sw/4.png',
    href: '#',
  },
];

export default function ScriptWriting() {
  pageTitle('Script Writing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Writing Stories that Captivate"
        subTitle="Script Writing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/sw/sw.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Great videos start with great scripts. Our scriptwriting services 
             provide the foundation for your visual storytelling, ensuring your 
             message is clear, engaging, and perfectly tailored to your audience.
              Whether for commercials, explainer videos, or corporate presentations, 
              we craft scripts that capture your brand's essence and convey your story 
              in a way that resonates deeply, making every word count.
            </p>
            <p>
             We transform your ideas into scripts that are not only creatively rich but 
             also strategically aligned with your goals. Our scripts set the stage 
             for memorable and impactful video content.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Elevate your storytelling with expertly written scripts. 
                Our SEO-driven, audience-focused scripts enhance your content's 
                impact and ensure your message stands out in any medium.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Conceptualization and Audience Analysis"
                  subTitle="Understand video purpose and audience to craft scripts that resonate effectively."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Storyline Development"
                  subTitle="Develop compelling narratives that capture attention and guide viewers toward desired actions."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="  Detailed Scriptwriting"
                  subTitle="Write clear, concise scripts with well-paced dialogue and scene descriptions."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Client Collaboration and Revisions"
                  subTitle="Refine scripts based on feedback, ensuring alignment with your vision and objectives."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Pre-Production Planning"
                  subTitle="Support planning for production, ensuring smooth execution of the script’s vision."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Script Integration in Production"
                  subTitle="Ensure the script guides video direction, maintaining consistency with the intended narrative."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
