import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. What technologies do you use for web development?',
    content:
      ' Our team uses a variety of technologies depending on the requirements of your project: HTML5, CSS3, JavaScript, PHP, Python, Ruby on Rails and more. We’re also experts in frameworks/CMS like WordPress.',
  },
  {
    title: '02. How do you ensure my website is secure?',
    content:
      'We ensure your security with our automated interaction with all the elements of data protection. That’s why we use SSL data encryption, secure coding standards, regular system and plugin updates, and conduct vulnerability scans to shield your website from hacker attacks and malicious activities.',
  },
  {
    title: '03. Can you integrate third-party services into my website?',
    content:
      'Yes, we are able to implement third-party systems that can connect to your website such as payments, back-office CRM, facebook or twitter etc. All third-party integrations will be secure and work well with your site.',
  },
  {
    title: '04. How long does it take to develop a website?',
    content:
      'Its depended on web site features, a simple website may take a few weeks of development while more complicated web project with some custom features takes several months. we will give you a detailed timeline when we have better understanding of your web site requirements.',
  },
  {
    title: '05. Do you offer ongoing support after the website is launched?',
    content:
      'Yes certainly! We will take good care of you by providing maintenance and support services: we will perform regular updates and ensure security patches to keep your website running smoothly after launch, and finally provide technical support to address any other arising issues.',
  },
];
const serviceListData = [
  {
    title: 'Seamless User Experience',
    subtitle:
      'We develop responsive, high-performance websites that offer a smooth and engaging experience across all devices.',
    imgUrl: '/images/sd/web/1.jpg',
    href: '#',
  },
  {
    title: 'Enhanced Performance',
    subtitle:
      'Our websites are optimized for fast loading speeds and efficient navigation, helping to keep users engaged and reduce bounce rates.',
    imgUrl: '/images/sd/web/2.jpg',
    href: '#',
  },
  {
    title: 'Robust Security',
    subtitle:
      ' We prioritize security in our development process, implementing measures to protect your website and customer data from threats.',
    imgUrl: '/images/sd/web/3.jpg',
    href: '#',
  },
  {
    title: 'Scalable Solutions',
    subtitle:
      ' Our web development solutions are built with scalability in mind, allowing your website to grow alongside your business.',
    imgUrl: '/images/sd/web/5.jpg',
    href: '#',
  },
];

export default function WebDevelopment() {
  pageTitle('Web Development');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Building Robust and Scalable Platforms"
        subTitle="Web Development"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/web/web.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Bring your website to life with our comprehensive Web Development services. 
             We specialize in building robust, scalable, and secure websites that are 
             tailored to meet your specific business needs. From e-commerce platforms 
             to custom web applications, we develop sites that not only perform seamlessly 
             but also support your business growth. Our focus is on delivering high-quality 
             code, ensuring your site is fast, responsive, and optimized for success.
            </p>
            <p>
              We turn your ideas into fully functional websites that are built to last. 
              Our development process prioritizes performance and security, ensuring 
              your site delivers an exceptional user experience across all devices.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Empower your business with custom, secure, and scalable 
                web solutions. Our expert team delivers fast-loading, 
                SEO-optimized websites that enhance your digital presence 
                and stand out from the competition.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Technical Discovery and Planning"
                  subTitle="We begin by conducting a thorough technical discovery session, where we assess your business needs, goals, and existing infrastructure. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Custom Development Solutions"
                  subTitle="Based on the plan, our development team creates custom solutions tailored to your specific needs. "
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Responsive Design Implementation"
                  subTitle="We ensure that your website is fully responsive, providing an optimal user experience across all devices, from desktops to smartphones. "
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="  Security and Compliance"
                  subTitle="Security is a top priority in our development process. We implement robust security measures, including SSL certificates,  and regular updates."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Performance Optimization"
                  subTitle="We optimize your website’s performance by focusing on load times, server response, and efficient coding practices."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Support and Maintenance"
                  subTitle="After the website goes live, we provide ongoing support and maintenance to ensure it remains secure, up-to-date, and fully functional."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
